<template>
  <div class="choose-us bgfff">
    <div class="container">
      <u-title title="为什么选择我们" />
      <div class="card-content row">
        <div class="card__video col-md-12 col-12">
          <video
            src="http://mashibing.com/msb/images/video/2020xuan.mp4"
            muted
            controls
            autoplay
          ></video>
        </div>
        <div class="card__reason p-0 m-0 row col-md-12 col-12">
          <div
            class="card__reasonList col-6 col-lg-3 col-md-6 col-xl-12"
            :class="item.class"
            v-for="(item, index) in reasonList"
            :key="index"
          >
            <div class="card__reasonTitle">
              <span></span>
              <span></span>
              {{ item.title }}
            </div>
            <div
              class="card__reasonSpan"
              v-for="(childItem, childIndex) in item.data"
              :key="childIndex"
            >
              {{ childItem }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      reasonList: [
        {
          class: 'd-xl-none',
          title: '荣誉同在',
          data: [
            '◇    2019年6月至今荣获：',
            '◇ “腾讯认证高质量服务机构”',
            '◇ “腾讯认证名师”',
            '◇ “腾讯101计划开路者”',
            '◇ “腾讯课堂最受欢迎奖”',
          ],
        },
        {
          class: 'd-xl-none',
          title: '服务体系',
          data: [
            '◇  量身定制学习计划',
            '◇  多对一学习辅导',
            '◇  班主任全程跟踪答疑解惑',
            '◇  就业、简历、面试指导、',
            '◇  满足短期跳槽、长期成长',
            '◇  转行入行等不同需求',
          ],
        },
        {
          title: '教学质量',
          data: [
            '◇  课程学习整体评价高',
            '◇  学员整体满意度高',
            '◇  高质量 “培”“训”结合',
            '◇  直达企业用人需求标准',
          ],
        },
        {
          title: '终身服务',
          data: [
            '◇  一次付费，终身VIP',
            '◇  专属一对一定制化学习',
            '◇  职业规划服务',
            '◇  200+互联网合作企业内推',
            '◇  精英猎头高薪offer通道',
            '◇  工作内容技术咨询',
          ],
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.choose-us {
  background-color: #f4f4f4;
  padding: 40px 0 40px;
}

.card {
  &__choose {
    display: flex;
    width: 100%;
  }
  &__video {
    height: calc(100% / 1.5) !important;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-radius: 5px;
    }
  }
  &__reason {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  &__reasonList {
    text-align: left;
    margin-top: 32px;
  }
  &__reasonTitle {
    font-size: 22px;
    color: #d75685;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &__reasonTitle span:nth-child(1) {
    width: 6px;
    height: 6px;
    background: rgba(182, 51, 136, 1);
    opacity: 0.3;
    border-radius: 50%;
    display: block;
  }
  &__reasonTitle span:nth-child(2) {
    width: 11px;
    height: 11px;
    background: rgba(182, 51, 136, 1);
    opacity: 0.3;
    border-radius: 50%;
    margin-left: 3px;
    margin-right: 10px;
  }
  &__reasonSpan {
    color: #8b8b8b;
    font-size: 12px;
  }
}
@media (min-width: 960px) {
  .choose-us {
    background-color: #fff;
    margin-bottom: 70px;
  }
  .card-content {
    margin-top: 50px;
    .card__video {
      // height: 410px !important;
    }
  }
  .card__reasonList {
    margin-top: 22px;

    .card__reasonSpan {
      font-size: 16px;
      line-height: 26px;
    }
  }
}
@media (min-width: 1200px) {
  .card__video {
    // max-width: 725px;
    flex-basis: 870px;
    flex-shrink: 0;
  }
  .card__reason {
    flex-basis: 260px;
  }
}
</style>
