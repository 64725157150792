<template>
  <div class="card">
    <div class="card__box">
      <u-title title="实战项目" />
      <div class="card__project">
        <div class="card__pleft">
          <div
            class="card__plist"
            @click="handleProject(index)"
            v-for="(item, index) in proList"
            :key="index"
          >
            <span>{{ item.name }}</span>
            <div class="card__img" v-if="projectActive === index">
              <img src="../../../../../assets/icon_1.png" alt />
            </div>
          </div>
        </div>
        <div class="card__pright">
          <swiper
            v-model="projectActive"
            :options="swiperProjectOption"
            @slideChange="slideChange"
            ref="projectSwiper"
          >
            <!-- slides -->
            <swiper-slide
              class="swiper-slide"
              v-for="(item, index) in proList"
              :key="index"
            >
              <div class="card__ptop">
                <div class="card__ptleft">
                  <div class="card__btitle" :style="{ marginTop: 80 }">
                    项目介绍：
                  </div>
                  <div class="card__bintro">{{ item.introIntro }}</div>
                  <div class="card__btitle" :style="{ marginTop: 60 }">
                    涉及技术点：
                  </div>
                </div>
                <img
                  class="card__bimg"
                  src="//www.mashibing.com/img/boxlist.png"
                  alt
                />
              </div>
              <div class="card__bintro">{{ item.skillIntro }}</div>
              <div class="card__pbottom">
                <a
                  class="more-btn"
                  style="margin-left: 150px"
                  target="_blank"
                  :href="$customerServiceLink"
                  >查看项目详情</a
                >
              </div>
            </swiper-slide>
            <!-- Optional controls -->
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['proList'],
  data () {
    return {
      projectActive: 0,
      swiperProjectOption: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  computed: {
    myProjectSwiper () {
      return this.$refs.projectSwiper.$swiper
    },
  },
  methods: {
    slideChange () {
      let number = this.proList.length
      let activeIndex = this.$refs.projectSwiper.swiperInstance.activeIndex
      if (activeIndex === number + 1) {
        this.projectActive = 0
      } else if (activeIndex === 0) {
        this.projectActive = number - 1
      } else {
        this.projectActive =
          this.$refs.projectSwiper.swiperInstance.activeIndex - 1
      }
    },
    // 实战项目
    handleProject (index) {
      this.projectActive = index
      this.myProjectSwiper.slideTo(index + 1, 1000, false)
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  width: 1200px;
  margin: 0 auto;
  &__project {
    display: flex;
  }
  &__pleft {
    height: 580px;
    width: 300px;
    flex-shrink: 0;
    background: url('//www.mashibing.com/img/project.png') no-repeat;
    background-size: contain;
    -webkit-background-size: contain;
    -o-background-size: contain;
    background-position: center 0;
    margin-right: 35px;
    padding-top: 80px;
    box-sizing: border-box;
  }
  &__plist {
    font-size: 20px;
    margin-top: 40px;
    text-align: right;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    position: relative;
    span {
      margin-right: 70px;
    }
    .card__img {
      width: 20px;
      height: 28px;
      background: #fff;
      position: absolute;
      right: 24px;
      img {
        height: 14px;
        width: 11px;
        position: absolute;
        top: 7px;
        left: 5px;
      }
    }
  }
  &__pright {
    height: 580px;
    width: 900px;
    background: url('//www.mashibing.com/img/icon_2.png') no-repeat;
    background-size: contain;
    -webkit-background-size: contain;
    -o-background-size: contain;
    background-position: center 0;
    position: relative;
    padding: 0 50px;
    box-sizing: border-box;
    text-align: left;
    /deep/ .swiper-slide {
      height: 100%;
      width: 100%;
      font-size: 50px;
      text-align: center;
    }
    /deep/ .swiper-pagination {
      left: unset;
      right: 20px;
      bottom: 40px;
      text-align: right;
      width: 200px;
    }
    /deep/ .swiper-container {
      height: 100%;
      width: 100%;
    }
    /deep/ .swiper-pagination-bullet {
      width: 16px;
      background: rgba(220, 220, 220, 1);
      border-radius: 4px;
    }
    /deep/ .swiper-pagination-bullet-active {
      width: 29px;
      border-radius: 4px;
      background: white;
    }
  }
  &__btitle {
    color: #b63388;
    text-align: left;
    font-size: 28px;
  }
  &__bintro {
    margin-top: 30px;
    margin-bottom: 60px;
    color: #7b7b7b;
    font-size: 16px;
    text-align: left;
  }
  &__ptop {
    display: flex;
  }
  &__ptleft {
    flex: 1;
    margin-top: 80px;
  }
  &__bimg {
    height: 260px;
    width: 270px;
    margin: 110px 38px 0 55px;
  }
  &__pbottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 26px;
    width: 100%;
    height: 40px;
  }

  &__product {
    margin-top: 20px;
    text-align: left;
    color: #7b7b7b;
    font-size: 16px;
  }
}
</style>
