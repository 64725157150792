<template>
  <div class="card">
    <div class="card__box">
      <u-title title="名师大咖" />
      <div class="card__boss container" id="teacher">
        <swiper :options="swiperBossOption" ref="mySwiper">
          <!-- slides -->
          <swiper-slide
            class="swiper-slide"
            v-for="(item, idx) in Math.ceil(list.length / 5)"
            :key="idx"
          >
            <div class="swiper-wrap">
              <div
                data-hover="teacher"
                class="card__teacher swiper-item-teacher"
                v-for="(teacher, index) in list.slice(idx * 5, (idx + 1) * 5)"
                :key="index"
              >
                <img
                  data-hover="teacher"
                  :src="teacher.src"
                  :alt="teacher.title"
                />
                <div data-hover="teacher" class="teacher-title">
                  {{ teacher.title }}
                </div>
                <div data-hover="teacher" class="swiper-item-info">
                  <div class="card__tname" data-hover="teacher">
                    {{ teacher.title }}
                  </div>
                  <div class="card__tposition" data-hover="teacher">
                    {{ teacher.post }}
                  </div>
                  <div class="card__tdescribe" data-hover="teacher">
                    {{ teacher.detail }}
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <a class="more-btn" target="_blank" :href="$customerServiceLink"
        >查看更多</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data () {
    return {
      swiperBossOption: {
        loop: true,
        initialSlide: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  mounted () {
    document.getElementById('teacher').addEventListener('mouseover', (e) => {
      let target = e.target || e.srcElement
      if (target.getAttribute('data-hover') === 'teacher') {
        const ele = target.closest('.swiper-item-teacher').children[2]
        ele.style.display = 'block'
      }
    })
    document.getElementById('teacher').addEventListener('mouseout', (e) => {
      let target = e.target || e.srcElement
      if (target.getAttribute('data-hover') === 'teacher') {
        const ele = target.closest('.swiper-item-teacher').children[2]
        ele.style.display = 'none'
      }
    })
  },
  methods: {
    onSwiperEnter (e, index) {
      console.log(e, index)
    },
    onSwiperLeave (e, index) {
      console.log('onSwiperLeave', e, index)
    },
  },
}
</script>

<style lang="scss" scoped>
.swiper-wrap {
  display: flex;
  // flex-wrap: wrap;
  justify-content: space-between;
}
/deep/ .swiper-wrapper {
  padding-bottom: 30px;
}
.swiper-pagination {
  bottom: 0;
  /deep/ .swiper-pagination-bullet {
    width: 14px;
    height: 7px;
    background: rgba(179, 179, 179, 1);
    border-radius: 3px;
    &.swiper-pagination-bullet-active {
      width: 28px;
      height: 7px;
      background: rgba(182, 51, 136, 1);
      border-radius: 3px;
    }
  }
}
.card {
  .card__box {
    margin-bottom: 60px;
  }
  &__boss {
    width: 100vw;
    margin-bottom: 50px;
  }

  &__teacher {
    width: 200px;
    height: 100%;
    margin-bottom: 10px;
    position: relative;
    border-radius: 5px 5px 0 0;
    box-shadow: 5px 3px 13px 0px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    img {
      width: 100%;
      // height: 250px;
    }
    .teacher-title {
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      line-height: 56px;
      padding-left: 5px;
      background: #fff;
    }
  }
  &__tdetail {
  }
  &__tldetail {
    text-align: left;
    left: 208px;
  }
  &__trdetail {
    text-align: right;
    left: -416px;
  }
  &__tname {
    font-size: 24px;
  }
  &__tposition {
    font-size: 16px;
    margin-top: 8px;
  }
  &__tdescribe {
    font-size: 14px;
    margin-top: 16px;
  }
  &__thover {
    position: absolute;
    top: 0;
    left: 0;
    height: 180px;
    width: 200px;
    background: #c21c83;
    opacity: 0.4;
  }
  &__project {
    display: flex;
  }
}
.swiper-item-info {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: rgba(194, 28, 131, 0.9);
  color: white;
  padding: 20px 25px;
  text-align: left;
  border-radius: 5px;
  overflow: hidden;
  .card__tname {
    font-size: 24px;
    font-weight: 700;
  }
}
</style>
