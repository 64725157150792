<template>
  <div id="teacher">
    <component :is="type" :list="bosslSlide" />
  </div>
</template>

<script>
import { isPC } from '@/common/common.js'
import pc from './pc'
import phone from './phone'
export default {
  components: {
    phone,
    pc
  },
  data() {
    return {
      seo: true,
      teacherList: [
        {
          src: '//www.mashibing.com/img/zhangfugang.jpg',
          title: '张富刚老师',
          detail:
            '5年大数据、人工智能开发经验，精通Hadoop、Storm/Jstorm、Spark，Flink，kafka等技术，尤其对于Spark、Flink有着独特的见解'
        },
        {
          src: '//www.mashibing.com/img/wulei.jpg',
          title: '吴磊老师',
          detail:
            '7年互联网开发经验，曾任职易车网，从事大数据和数据可视化研发，参与和设计搜索引擎的研发，亿级日PV，对处理海量用户搜索请求有丰富经验。'
        },
        {
          src: '//static.mashibing.cn//lufengkun.png',
          title: '路丰坤老师',
          detail:
            '路丰坤 10年的AI算法开发经验，5年深度学习授课经验。先后任职于百度、海康威视、药明康德、国家生命科学研究所、新晨科技股份有限公司等公司。'
        },

        {
          src: '//www.mashibing.com/img/liyanliang.jpg',
          title: '李彦亮老师',
          detail:
            '13年IT行业工作经验！授课风趣幽默、课程中注重思想的传播、注重学习方法和学习习惯的养成。实战派专家，先后就职于中国住房公积金管理中心、中国工商银行、Oracle甲骨文公司。'
        },
        {
          src: '//www.mashibing.com/img/zhaopengfei.jpg',
          title: '晁鹏飞老师',
          detail:
            '9年IT工作经验，历任程序员，开发组长，服务端负责人。有传统信息系统开发，电子政务，移动医疗，车联网，网约车等多个项目开发与管理经验，曾就职于网秦，阿里等企业。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/lianpengju.jpg',
          title: '连鹏举老师',
          detail:
            '5年大数据工作经验，精通SSM，SpringBoot，SpringCloud等主流Java开源框架以及Hadoop，Kafka，Hive，HBASE Storm，Spark，Kylin等主流大数据技术。'
        },
        {
          src: '//www.mashibing.com/img/zhouzhilei.jpg',
          title: '周志垒老师',
          detail:
            '十年以上IT工作经验，四年教学经验，架构师、大数据全栈讲师、教学总监；有通信、金融、互联网、生产企业等行业项目管理背景；学员幽默的评价为"跪着听"周老师的课！'
        },
        {
          src: '//www.mashibing.com/img/zhaoshanshan.jpg',
          title: '赵珊珊老师',
          detail:
            '赵珊珊，世界500强内训专家讲师，税务系统业务专家，从事多年培训行业。培养学员3000+，人美声甜会讲课，学生点名上课，深受欢迎。'
        },
        {
          src: '//www.mashibing.com/img/zhangyiming.jpg',
          title: '张一明老师',
          detail:
            '众信旅游（上市集团） 移动技术部负责人、产品设计、技术架构 北京精智教育科技有限公司（创业股份制） 联合创始人 CTO 中国石化（国企） 大数据高级顾问'
        },
        {
          src: '//www.mashibing.com/img/zcy.jpg',
          title: '左程云老师',
          detail:
            '《程序员代码面试指南》作者，先后就职于IBM、百度、GrowingIO、amazon。从事算法教学工作6年。'
        },
        {
          src: '//www.mashibing.com/img/huangjun.jpg',
          title: '黄俊老师',
          detail:
            '曾任职于北京拓尔思、北京人人网、北京掌阅科技、阿里巴巴等多家企业！ 精通JAVA、C、Hotspot、JVM、Linux内核、微服务、架构设计、各大开源框架源码，对领域驱动模型有独特见解。'
        },
        {
          src: '//www.mashibing.com/img/xiaobing.jpg',
          title: '肖斌老师',
          detail:
            '从业10余年，7年教学经验，积累了丰富的教学经验和项目研发经验，精通javaEE、oracle、流行框架等技术，擅长电商平台、政符项目、企业级应用、金融项目研发。'
        },
        {
          src: '//www.mashibing.com/img/yangshujuan.jpg',
          title: '杨淑娟老师',
          detail:
            '国内知名Java技术专家，Python技术专家，数据库技术专家，从事了近10年的教育培训，培训学员上万人次，2016被腾讯教育评为教育培训界的“中国好老师”。'
        },
        {
          src: '//www.mashibing.com/img/jiachenghao.jpg',
          title: '贾成豪老师',
          detail:
            '曾负责公司内部框架的开发和维护，实战经验丰富！贾成豪老师对Object—C、Swift、node.js、react、vue等时下最流行的技术有深入研究。'
        }
      ],
      bosslSlide: [
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        },
        {
          src: '//www.mashibing.com/img/mashibing.jpg',
          title: '马士兵老师',
          detail:
            '1994-1999 年就读于清华大学， 推动Java生根中国，推动大数据生根中国，推动AI生根中国，视频课程下载次数累计数十亿次。 目前正致力于打造像面授一样高质量的网课平台。'
        }
      ]
    }
  },
  computed: {
    type() {
      return isPC ? 'pc' : 'phone'
    }
  },
  mounted() {
    if (window.__PRERENDER_INJECTED__ === 'prerender') return
    setTimeout(() => {
      this.bosslSlide = this.teacherList
    }, 200)
  }
}
</script>

<style></style>

<style lang="scss" scoped>
.swiper-slide {
  width: 135px !important;
}
.card__boss {
  width: 100%;
  height: 290px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 120px;
    object-fit: fill;
  }
  &__title {
    font-size: 15px;
    font-weight: 500;
    margin-top: 15px;
    color: rgba(0, 0, 0, 1);
  }
  &__post {
    font-size: 11px;
    margin-top: 8px;
    color: #888888;
    font-weight: 500;
    color: rgba(137, 137, 137, 1);
    line-height: 15px;
  }
  &__detail {
    margin-top: 8px;
    font-size: 9px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    line-height: 12px;
  }
}
</style>
