<template>
  <div class="">
    <u-title title="名师大咖" />
    <div class="card__content">
      <swiper :options="swiperIphoneBossOption">
        <swiper-slide
          class="swiper-slide"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="card__list">
            <div class="card__boss">
              <img :src="item.src" alt />
              <div class="card__boss__title">{{ item.title }}</div>
              <div class="card__boss__post">{{ item.post }}</div>
              <div class="card__boss__detail">{{ item.detail }}</div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data() {
    return {
      swiperIphoneBossOption: {
        effect: 'coverflow',
        slideToClickedSlide: true,
        centeredSlides: true,
        spaceBetween: '10%',
        slidesPerView: 'auto',
        loop: true,
        autoplay: false, // 是否自动播放
        initialSlide: 5,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false
        }
      }
    }
  }
}
</script>

<style></style>

<style lang="scss" scoped>
.swiper-slide {
  width: 135px !important;
}
.card__boss {
  width: 100%;
  // height: 290px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    // height: 120px;
    object-fit: fill;
  }
  &__title {
    font-size: 15px;
    font-weight: 500;
    margin-top: 15px;
    color: rgba(0, 0, 0, 1);
  }
  &__post {
    font-size: 11px;
    margin-top: 8px;
    color: #888888;
    font-weight: 500;
    color: rgba(137, 137, 137, 1);
    line-height: 15px;
  }
  &__detail {
    margin-top: 8px;
    font-size: 10px;
    // font-weight: 500;
    color: rgba(0, 0, 0, 1);
    line-height: 16px;
  }
}
</style>
