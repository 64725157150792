<template>
  <div class="introversion bgfff">
    <div class="container">
      <u-title title="合作企业内推" />
      <div class="introversion-list ml-0 mr-0 row">
        <div
          class="introversion-item"
          v-for="(item, index) in list"
          :key="index"
        >
          <img :src="item.img" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        { img: '//www.mashibing.com/img/logo1.jpg' },
        { img: '//www.mashibing.com/img/logo2.jpg' },
        { img: '//www.mashibing.com/img/logo3.jpg' },
        { img: '//www.mashibing.com/img/logo4.jpg' },
        { img: '//www.mashibing.com/img/logo5.jpg' },
        { img: '//www.mashibing.com/img/logo6.jpg' },
        { img: '//www.mashibing.com/img/logo7.jpg' },
        { img: '//www.mashibing.com/img/logo8.jpg' },
        { img: '//www.mashibing.com/img/logo9.jpg' },
        { img: '//www.mashibing.com/img/logo10.jpg' },
        { img: '//www.mashibing.com/img/logo11.jpg' },
        { img: '//www.mashibing.com/img/logo12.jpg' },
        { img: '//www.mashibing.com/img/logo13.jpg' },
        { img: '//www.mashibing.com/img/logo14.jpg' },
        { img: '//www.mashibing.com/img/logo15.jpg' },
        { img: '//www.mashibing.com/img/logo16.jpg' },
        { img: '//www.mashibing.com/img/logo17.jpg' },
        { img: '//www.mashibing.com/img/logo18.jpg' },
        { img: '//www.mashibing.com/img/logo19.jpg' },
        { img: '//www.mashibing.com/img/logo20.jpg' },
        { img: '//www.mashibing.com/img/logo21.jpg' },
        { img: '//www.mashibing.com/img/logo22.jpg' },
        { img: '//www.mashibing.com/img/logo23.jpg' },
        { img: '//www.mashibing.com/img/logo24.jpg' },
        { img: '//www.mashibing.com/img/logo25.jpg' },
        { img: '//www.mashibing.com/img/logo26.jpg' },
        { img: '//www.mashibing.com/img/logo27.jpg' },
        { img: '//www.mashibing.com/img/logo28.jpg' },
        { img: '//www.mashibing.com/img/logo29.jpg' },
        { img: '//www.mashibing.com/img/logo30.jpg' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.introversion {
  .introversion-list {
  }
  .introversion-item {
    flex: 0 0 calc(33.33% - 15px);
    max-width: calc(33.33% - 15px);
    margin-right: 22px;
    margin-bottom: 20px;
    padding: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.11);
    &:nth-child(3n) {
      margin-right: 0;
    }
    img {
      max-width: 100%;
      max-height: 30px;
      transition: all 0.6s;
      cursor: pointer;
    }
    img:hover {
      transform: scale(1.1);
    }
  }
}
@media (min-width: 768px) {
  .introversion {
    .introversion-item {
      flex: 0 0 calc(20% - 18px);
      max-width: calc(20% - 18px);
      margin-right: 22px;
      margin-bottom: 30px;
      padding: 20px;
      &:nth-child(3n) {
        margin-right: 22px;
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
</style>
