<template>
  <div class="container" id="hot">
    <u-title title="热门训练营" />
    <div class="hot-list">
      <a
        class="hot-item"
        target="_blank"
        v-for="(item, index) in cardList"
        :href="item.link"
        :key="index"
      >
        <div class="card__picture">
          <img :src="item.src" alt />
          <div class="card__cuser">
            <img src="../../../../../assets/user.png" alt />
            <span>主讲老师：</span>
            <span>{{ item.teacher }}</span>
          </div>
        </div>
        <div class="card__detail">
          <div class="card__price">
            <span class="card__new">￥{{ item.price }}</span>
            <span class="card__old">￥{{ item.old }}</span>
            <span class="card__tip">限时优惠</span>
          </div>
          <div class="card__person">{{ item.person }}人购买</div>
        </div>
      </a>
    </div>
    <a class="more-btn" target="_blank" :href="$customerServiceLink"
      >查看更多</a
    >
  </div>
</template>

<script>
export default {
  data () {
    return {
      swiperContentOption: {
        loop: true,
        speed: 3000,
        slidesPerView: 2.1,
      },
      cardList: [
        {
          link: 'https://ke.qq.com/course/2770807',
          price: 1,
          old: 2399,
          person: 5729,
          src: '//www.mashibing.com/img/hot2.jpg',
          teacher: '马老师',
        },
        {
          link: 'https://ke.qq.com/course/2774006',
          price: 1,
          old: 1999,
          person: 3137,
          src: '//www.mashibing.com/img/hot3.jpg',
          teacher: '周老师',
        },
        {
          link: 'https://ke.qq.com/course/2774006',
          price: 1,
          old: 999,
          person: 1441,
          src: '//www.mashibing.com/img/hot4.jpg',
          teacher: '张老师',
        },
        {
          link: 'https://ke.qq.com/course/2736876',
          price: 1,
          old: 899,
          person: 3423,
          src: '//www.mashibing.com/img/hot1.jpg',
          teacher: '连老师',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.hot-list {
  display: flex;
}
.hot-item {
  width: calc(25% - 20px);
  flex-shrink: 0;
  margin-right: 24px;
  &:nth-child(4n) {
    margin-right: 0 !important;
  }
  .card__picture {
    width: 100%;
    // height: 150px;
    overflow: hidden;
    border-radius: 2px;
    position: relative;
  }
  .card__picture > img {
    height: 100%;
    width: 100%;
    transition: all 0.6s;
    cursor: pointer;
  }
  .card__picture > img:hover {
    transform: scale(1.1);
  }
  .card__cuser {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    text-align: left;
    height: 32px;
    display: flex;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.1);
    img {
      width: 20px;
      height: 14px;
      margin: 0 11px 0 10px;
      z-index: 20;
    }
    span {
      z-index: 20;
    }
  }
  .card__detail {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
  }
  .card__price {
    display: flex;
    align-items: center;
  }
  .card__new {
    font-size: 16px;
    color: #fe6221;
    margin-right: 7px;
  }
  .card__old {
    color: #9a9a9a;
    font-size: 14px;
    text-decoration: line-through;
    margin-right: 4px;
  }
  .card__tip {
    font-size: 14px;
    color: #ff4b00;
  }
  .card__person {
    color: #7b7b7b;
  }
}
.more-btn {
  margin: 30px 0;
}
@media (max-width: 768px) {
  .hot-list {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    .hot-item {
      width: 158px !important;
      flex-shrink: 0;
      margin-right: 12px;
      .card__picture {
        height: 84px;
      }
      .card__cuser {
        height: 18px;
        font-size: 12px;
        img {
          width: 12px;
          height: 8px;
        }
      }
      .card__price {
        position: relative;
        padding-bottom: 20px;
        .card__tip {
          position: absolute;
          left: 0;
          top: 20px;
        }
      }
      .card__person {
        position: relative;
        top: 2px;
      }
    }
  }
}
</style>
