<template>
  <div>
    <Header />
    <div class="intro container">
      <img
        class="logo"
        title="马士兵教育logo"
        alt="马士兵教育logo"
        src="//www.mashibing.com/img/msblogo.png"
      />
      <div class="input">
        <input
          class="text"
          @keydown.enter="search"
          v-model="keyword"
          type="text"
        />
        <div class="search" @click="search">
          <img src="../../../../../assets/search.png" alt />
        </div>
      </div>
      <div class="iphone">
        <img class="icon" src="../../../../../assets/tel.png" alt />
        <div class="content">
          <div class="text">24小时客服电话</div>
          <div class="number">4000015096</div>
        </div>
      </div>
    </div>
    <div class="tabnav">
      <div class="tabList">
        <div class="box container">
          <div class="hot">热门课程</div>
          <div class="list">
            <a
              :href="item.link"
              v-for="(item, index) in tabList"
              :target="item.target"
              :key="index"
            >
              {{ item.title }}
            </a>
          </div>
        </div>
      </div>
      <div class="tabDetail">
        <div class="picture">
          <swiper :options="swiperOption">
            <swiper-slide
              class="swiper-slide"
              v-for="(item, index) in bannerList"
              :key="index"
            >
              <a :href="item.link" target="_blank">
                <img :src="item.src" alt />
              </a>
            </swiper-slide>
            <!-- 分页器 -->
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="box container">
          <div class="detailList">
            <div class="tabCourseDetail">
              <a
                href="https://ke.qq.com/course/package/20773"
                target="_blank"
                class="tabCourse"
                >互联网P8架构师<span>></span></a
              >
            </div>
            <div
              class="tabCourseDetail"
              v-for="(item, index) in courseList"
              :key="index"
            >
              <a :href="item.link" target="_blank" class="tabCourse"
                >{{ item.title }} <span>></span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
export default {
  components: {
    Header,
  },
  props: ['swiperOption', 'slide', 'tabList', 'courseList', 'notice'],
  data() {
    return {
      keyword: '',
      bannerList: [
        {
          src: '//www.mashibing.com/img/banner1.jpg',
          link: 'https://ke.qq.com/course/package/20773',
        },
        // {
        //   src: '//www.mashibing.com/img/banner2.jpg?v',
        //   link: 'https://ke.qq.com/course/package/27380',
        // },
        {
          src: '//www.mashibing.com/img/banner3.jpg',
          link: 'https://ke.qq.com/course/package/20773',
        },
        // {
        //   src: '//www.mashibing.com/img/banner6.jpg',
        //   link: 'https://ke.qq.com/course/398321',
        // },
        {
          src: '//www.mashibing.com/img/banner7.jpg',
          link: 'https://ke.qq.com/course/2770807',
        },
      ],
    };
  },
  methods: {
    search() {
      if (this.keyword) {
        window.open(
          `https://italk.mashibing.com/search?keywords=${this.keyword}&type=question`
        );
        this.keyword = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.intro {
  height: 80px;
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  margin-bottom: 40px;
  align-items: center;
  .input {
    width: 450px;
    display: flex;
    align-items: center;
    height: 44px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(182, 51, 136, 1);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    .text {
      width: 100%;
      height: 44px;
      background: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      border: none;
    }
    .search {
      height: 100%;
      flex: 1;
      background: #b63388;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      width: 56px;
      cursor: pointer;
    }
    .search img {
      height: 21px;
      width: 17px;
    }
  }
  .iphone {
    display: flex;
    .icon {
      height: 28px;
      width: 28px;
      margin-right: 10px;
    }
    .content {
      text-align: left;
      .text {
        color: #858585;
        font-size: 12px;
        margin-bottom: 4px;
      }
      .number {
        color: #d93199;
        font-size: 24px;
      }
    }
  }
  img {
    height: 80px;
    width: 190px;
  }
}
.tabnav {
  .tabList {
    display: flex;
    justify-content: center;
    .box {
      // width: 1200px;
      display: flex;
      .hot {
        width: 230px;
        height: 60px;
        background: rgba(194, 28, 131, 1);
        border-radius: 10px 10px 0px 0px;
        color: white;
        font-size: 24px;
        line-height: 60px;
      }
      .list {
        display: flex;
        justify-content: space-around;
        flex: 1;
        align-items: center;
        font-size: 18px;
        a:nth-last-child(2) {
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 58px;
            height: 19px;
            position: absolute;
            background: url('~@/assets/mf_03.png');
            top: -20px;
            right: 0;
          }
        }
        span {
          cursor: pointer;
        }
      }
    }
  }
  .tabDetail {
    height: 335px;
    position: relative;
    display: flex;
    justify-content: center;
    .picture {
      height: 100%;
      width: 100%;
      max-width: 2560px;
      margin: 0 auto;
      position: absolute;
      top: 0;
      // left: 0;
      /deep/ .swiper-pagination-bullet {
        background: #dbdbdb;
      }
      /deep/ .swiper-pagination-bullet-active {
        background: white;
      }
    }
    .box {
      // width: 1200px;
      .detailList {
        width: 230px;
        background: black;
        height: 100%;
        color: white;
        opacity: 0.5;
        overflow: hidden;
        font-size: 16px;
        z-index: 20;
        position: absolute;
        .tabCourseDetail {
          margin-top: 16px;
          text-align: right;
          margin-right: 30px;
          cursor: pointer;
          span {
            margin-left: 15px;
          }
        }
      }
    }
    /deep/ .swiper-slide {
      height: 100%;
      width: 100%;
      text-align: center;
      overflow: hidden;
      a {
        display: flex;
        justify-content: center;
        height: 100%;
      }
      // vertical-align: middle;
    }
    /deep/ .swiper-slide img {
      height: 100%;
      width: 2560px;
      // width: 100%;
    }
    /deep/ .swiper-container {
      height: 100%;
    }
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .intro .logo {
    flex-shrink: 0;
    width: 150px;
    height: auto;
    margin-right: 20px;
  }
  .tabnav .tabDetail {
    height: 300px;
  }
  .tabnav .tabList .box .hot {
    width: 180px;
    font-size: 16px;
  }
  .tabnav .tabList .box .list {
    display: flex;
    justify-content: space-around;
    flex: 1;
    align-items: center;
    font-size: 14px;
  }
  .tabnav .tabDetail .box .detailList {
    width: 180px;
    font-size: 14px;
  }
}
</style>
