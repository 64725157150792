<template>
  <div>
    <pc
      class="d-none d-md-block d-lg-block d-xl-block"
      :swiperOption="swiperOption"
      :tabList="tabList"
      :courseList="courseList"
    />
    <phone
      class="d-block d-md-none d-lg-none d-xl-none"
      :swiperOption="swiperOption"
      :tabList="tabList"
      :courseList="courseList"
    />
    <div class="notice container">
      <div class="box">
        <div class="notice__title d-block d-md-none">公告</div>
        <div class="notice__split d-block d-md-none"></div>
        <div class="title d-none d-md-block">
          最新公告:
          <img src="../../../../../assets/shadow.png" alt />
        </div>
        <div class="detail">
          <transition class="inner-container" name="slide" mode="out-in">
            <p class="text" :key="sorollText.id">{{ sorollText.val }}</p>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isPC, isPad } from '@/common/common.js'
import pc from './pc'
import phone from './phone'

export default {
  components: {
    phone,
    pc,
  },
  data () {
    return {
      number: 0,
      scrollArr: [
        '马老师直播《JVM调优》训练营即将开营！立即预约！',
        '金九银十跳槽季，现在报名可享专属优惠！',
      ],
      courseList: [
        {
          title: 'JavaEE高薪就业班',
          link: 'https://ke.qq.com/course/2438176',
        },
        {
          title: 'java互联网架构',
          link: 'https://ke.qq.com/course/398381',
        },
        {
          title: 'Python全栈',
          link: 'https://ke.qq.com/course/2379892',
        },
        {
          title: 'AI人工智能',
          link: 'https://ke.qq.com/course/423416',
        },
        {
          title: 'Web前端全栈',
          link: 'https://ke.qq.com/course/2737328',
        },
        {
          title: '大数据开发架构师',
          link: 'https://ke.qq.com/course/398321',
        },
        { title: 'Web安全渗透', link: 'https://ke.qq.com/course/2837583' },
      ],
      tabList: [
        {
          key: '0',
          title: '首页',
          link: '#',
        },
        {
          key: '1',
          title: '马士兵课堂',
          target: '_blank',
          link: 'http://mashibing.com/msb_college.html',
        },
        {
          key: '2',
          title: '优秀讲师',
          link: '#teacher',
        },
        {
          key: '3',
          title: '所有课程',
          target: '_blank',
          link: 'https://masb.ke.qq.com/#category=-1&tab=1',
        },
        {
          key: '4',
          title: '热门训练营',
          link: '#hot',
        },
        {
          key: '5',
          title: '优秀学员',
          target: '_blank',
          link: 'http://www.mashibing.cn:8088/goodJob',
        },
        {
          key: '6',
          title: '行业资讯',
          target: '_blank',
          link: 'http://mashibing.com/msb/news/new1.html',
        },
        {
          key: '7',
          title: '课程体系',
          target: '_blank',
          link: '/course-system',
        },
        {
          key: '8',
          title: '学习平台',
          target: '_blank',
          link: 'https://italk.mashibing.com/course',
          mobileLink: 'https://italk.mobile.mashibing.com/#/'
        },
        {
          key: '9',
          title: '课程升级',
          target: '_blank',
          link: '/arithmetic',
        }
      ],
      swiperOption: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 2000, //1秒切换一次
        },
      },
    }
  },
  computed: {
    type () {
      console.log('type computer')
      return isPC || isPad ? 'pc' : 'phone'
    },
    sorollText () {
      return {
        id: this.number,
        val: this.scrollArr[this.number],
      }
    },
  },
  mounted () {
    this.startMove()
  },
  methods: {
    startMove () {
      const totalDuration = 2000
      this.timer = setTimeout(() => {
        this.number += 1
        if (this.number > this.scrollArr.length - 1) {
          this.number = 0
        }
        this.startMove()
      }, totalDuration)
    },
  },
}
</script>

<style lang="scss" scoped>
.notice {
  display: flex;
  // justify-content: center;
  margin-top: 40px;
  margin-bottom: 46px;
  .box {
    display: flex;
    // width: 1200px;
    text-align: left;
    align-items: center;
    .title {
      font-size: 18px;
      color: black;
      margin-right: 26px;
      position: relative;
      font-weight: bold;
      > img {
        position: absolute;
        z-index: -1;
        height: 10px;
        left: 0;
        width: 53px;
        object-fit: none;
      }
    }
    .detail {
      font-size: 16px;
      height: 27px;
      line-height: 27px;
      overflow: hidden;
      .slide-enter-active,
      .slide-leave-active {
        transition: all 0.5s linear;
      }
      .slide-leave-to {
        transform: translateY(-20px);
      }
      .slide-enter {
        transform: translateY(20px);
      }
    }
  }
}
@media (max-width: 768px) {
  .notice {
    height: 40px;
    max-width: 100%;
    background: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin: 0;
    &__title {
      font-size: 14px;
      color: #f33443;
    }
    &__split {
      width: 1px;
      height: 41px;
      opacity: 0.5;
      background: #f4f4f4;
      margin: 0 9px;
    }
    .detail {
      font-size: 12px !important;
    }
  }
}
</style>
