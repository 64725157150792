<template>
  <div class="card">
    <u-title title="项目实战" />
    <div class="card__box">
      <div class="card__content">
        <div class="card__project">
          <div class="card__ptop container">
            <swiper :options="swiperIphoneProjectOption">
              <swiper-slide
                class="swiper-slide"
                v-for="(item, index) in proList"
                :key="index"
              >
                <div class="card__pswiper" @click="handleProject(index)">
                  <span>{{ item.name }}</span>
                  <div
                    v-if="projectIndex === index"
                    class="card__pswipericon"
                  ></div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="card__pbottom container">
            <div class="card__ptitle">项目介绍：</div>
            <div class="card__pdetail">
              {{ proList[projectIndex].introIntro }}
            </div>
            <div class="card__ptitle">设计知识点：</div>
            <div class="card__pdetail">
              {{ proList[projectIndex].skillIntro }}
            </div>
          </div>
        </div>
      </div>
      <a
        class="more-btn"
        style="margin: 20px 0"
        target="_blank"
        :href="$customerServiceLink"
        >查看项目详情</a
      >
      <!-- <div class="card__more">查看实战项目</div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['proList'],
  data () {
    return {
      projectIndex: 0,
      swiperIphoneProjectOption: {
        slidesPerView: 'auto',
        // loopedSlides: 3,
      },
    }
  },
  methods: {
    handleProject (index) {
      this.projectIndex = index
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  &__project {
    width: 100%;
  }
  .card__ptop {
    .swiper-slide {
      width: auto !important;
      padding: 0 40px 0 0;
    }
  }
  &__pbottom {
    text-align: left;
  }
  &__ptitle {
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin-top: 18px;
  }
  &__pdetail {
    font-size: 12px;
    color: #7a7a7a;
    margin-top: 18px;
  }
  &__chover {
    height: 100%;
    width: 100%;
    position: absolute;
    background: black;
    opacity: 0.3;
  }
  &__detail {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }
  &__product {
    margin-top: 10px;
    text-align: left;
    color: #5a5a5a;
    font-size: 11px;
  }
  &__pswiper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #c01b83;
    font-size: 13px;
    height: 34px;
  }
  &__pswipericon {
    width: 50%;
    height: 6px;
    background: linear-gradient(
      93deg,
      rgba(201, 79, 151, 1) 0%,
      rgba(255, 95, 194, 1) 100%
    );
    border-radius: 3px;
  }
}
</style>
