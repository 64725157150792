<template>
  <div>
    <!-- 首页广告 展示  -->
    <!-- <div class="top_title--bg" ref="barparent" :style="'width: 100%;height: '+ hg_in +';'" onclick="max_from_company_mini(this);">
      <img v-show="srcShow" :src="src" alt="">
      <img v-show="src1Show" :src="src1" alt="">
    </div> -->
    <div class="pc" :style="'transform: translateY(-'+ hgChange +'px);'">

      <first-screen/>

      <!-- 热门训练营 -->
      <hot/>
      <!-- 小白入门 -->
      <classify/>
      <!-- 精品小课 -->
      <excellent-course/>
      <!-- 名师大咖 -->
      <teacher/>
      <!-- 实战项目 -->
      <project/>
      <!-- 为什么选择我们 -->
      <choose-us/>
      <!-- 我们的荣誉 -->
      <div class="card">
        <div class="card__box container">
          <u-title title="我们的荣誉"/>
          <div class="card__honor">
            <div
                class="card__honorList"
                v-for="(item, index) in honorList"
                :key="index"
            >
              <img :src="item" alt/>
            </div>
          </div>
        </div>
      </div>
      <!-- 合作企业内推 -->
      <introversion/>
      <div class="website">
        <a
            class="websiteList"
            :href="item.link"
            target="_blank"
            v-for="(item, index) in websiteList"
            :key="index"
        >
          {{ item.title }}
        </a>
      </div>
      <Footer/>
    </div>
  </div>

</template>

<script>
import chooseUs from '../home/components/chooseUs'
import introversion from '../home/components/introversion'
import excellentCourse from '../home/components/excellentCourse'
import classify from '../home/components/classify'
import teacher from '../home/components/teacher'
import hot from '../home/components/hot'
import firstScreen from '../home/components/firstScreen'
import project from '../home/components/project'
import Footer from '@/components/Footer'

export default {
  name: 'pc',
  components: {
    Footer,
    chooseUs,
    introversion,
    excellentCourse,
    hot,
    firstScreen,
    teacher,
    classify,
    project
  },
  data() {
    return {
      hg: '',
      hg_in: '',
      hgChange: 0,
      srcShow: true,
      src1Show: false,
      src: require('../../assets/top-title-bg-init.jpg'),
      src1: require('../../assets/top-title-bg.jpg'),
      start: true,
      innerWidth: '',
      innerHeight: ''
    }
  },
  props: {
    honorList: Array,
    websiteList: Array
  },
  created() {
    const timer = setTimeout(() => {
        this.hg = this.$refs.barparent.offsetHeight;
        console.log(this.hg)
        const timer2 = setInterval(() => {
          if ( this.start &&  this.hgChange < this.hg - 60) {
            this.hgChange = this.hgChange + 8
          } else {
            this.src1Show = true
            this.hgChange = 0
            this.srcShow = false

            // this.hg = this.$refs.barparent.offsetHeight;
            this.start = false
          }

        }, 1)

        this.$once('hook:beforeDestroy', () => {
          clearInterval(timer2)
        })
      }, 4000)
      this.$once('hook:beforeDestroy', () => {
        clearTimeout(timer)
      })

  },
  mounted() {
    // if(this.start) {

    // }
  },
  methods: {},
  destroyed() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/functions.scss';


.top_title--bg {
  cursor: pointer;
  //background: url("http://mashibing.com/img/top-title-bg-init.b2a55f7c.jpg") no-repeat center top;
  img {
    width: 100%;
    height: 100%;
  }
}

.card {
  // display: flex;
  // justify-content: center;
  // margin-bottom: 60px;
  &__box {
    // width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__show {
    position: relative;

    > img {
      position: absolute;
      z-index: -1;
      height: 13px;
      bottom: 0;
      left: 0;
    }
  }

  &__honor {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 30px;
  }

  &__honorList {
    // width: 150px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow: hidden;
    img {
      transition: all 0.6s;
      cursor: pointer;
    }

    img:hover {
      transform: scale(1.1);
    }
  }
}

.website {
  height: 62px;
  margin-top: 65px;
  background: #b63388;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;

  .websiteList {
    margin-left: 20px;
  }
}

@keyframes myMove {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-30px);
  }
  20% {
    transform: translateY(-30px);
  }
  30% {
    transform: translateY(-60px);
  }
  40% {
    transform: translateY(-60px);
  }
  50% {
    transform: translateY(-90px);
  }
  60% {
    transform: translateY(-90px);
  }
  70% {
    transform: translateY(-120px);
  }
  80% {
    transform: translateY(-120px);
  }
  90% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-150px);
  }
}

.text,
.text2 {
  margin: 0;
}

.inner-container {
  animation: myMove 5s linear infinite;
  animation-fill-mode: forwards;
}

input {
  outline-color: #c21c83;
}
</style>

<style>

.home,
.pc{
  background-color: #ffffff;
}
</style>
