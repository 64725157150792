<template>
  <div class="fot-wrp">

    <div class="box-l">
      <img src="@/assets/msb-icon.png" alt="">
    </div>
    <div class="box-c">
      <div class="title">马士兵教育</div>
      <div class="des">打开APP 学习更多优惠好课</div>
    </div>
    <div class="box-r">
        <a href="http://app.mashibing.cn/mashibing" target="_blank">立即下载</a>
    </div>

  </div>
</template>
<script>
    export default {
        data:() => ({

        }),
        created () {

        },
        methods: {

        },
    }
</script>
<style lang="scss">
  .fot-wrp{
    z-index: 999;
    position: fixed;
    transform: translateY(60px);
    width: 100%;
    height: 60px;
    background-color: #FFFFFF;;
    bottom: 60px;
    display: flex;
    padding: 10px 15px;
    .box-l{
      //flex: 1;
      height: 41px;
      width: 41px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .box-c {
      flex: auto;
      padding: 0 10px;
      color: #333333;

      .title {
        font-size: 16px;
      }
      .des {
        font-size: 12px;

      }
      text-align: left;
    }
    .box-r {
      flex: 1;
      padding: 7px;
      a{
        width: 80px;
        height: 28px;
        font-size: 14px;
        font-weight: 800;
        padding: 4px 8px;
        border-radius: 5px;
        color: #FFFFFF;
        background-color: #FB011A;
      }
    }
  }
</style>
