<template>
  <div class="footer">
    <div class="footer__top d-none">为中国IT事业打造更多更专业的技术人才</div>
    <div class="footer__box container">
      <div class="footer__t">
        <div class="footer__hd d-none d-lg-flex">
          <a
            class="footer__hdList"
            target="_blank"
            v-for="(item, index) in hdList"
            :href="item.link"
            :key="index"
          >
            <span>{{ item.title }}</span>
            <span
              v-for="(childItem, childIndex) in item.data"
              :key="childIndex"
              >{{ childItem }}</span
            >
          </a>
        </div>
        <div class="footer__split d-none d-lg-block"></div>
        <div class="footer__bd">
          <div class="footer__hdList mr-lg-4 d-none d-lg-block">
            <span :style="{ textAlign: 'left' }">{{ bdList.title }}</span>
            <!-- <a
                :style="{ textAlign: 'left' }"
                target="_blank"
                href="http://wpa.qq.com/msgrd?v=3&uin=1669964656&site=http://mashibing.com/&menu=yes"
              >
                <img src="~@/assets/icons-qq.png" alt="" /> QQ：1669964656</a
              > -->
            <span
              :style="{ textAlign: 'left' }"
              v-for="(childItem, childIndex) in bdList.data"
              :key="childIndex"
              >{{ childItem }}</span
            >
          </div>
          <div class="footer__bdCardList">
            <div class="footer__hdCard">
              <img src="//www.mashibing.com/img/wx.jpg" alt />
              <span>微信公众号</span>
            </div>
            <div class="footer__hdCard">
              <img src="//www.mashibing.com/img/consultant.png" alt />
              <span>课程顾问</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer__b">
        <div class="footer__bl">
          <div class="footer__address">
            {{ company }} 地址：{{ address }}
          </div>
          <div><a href="https://beian.miit.gov.cn/" target="view_window">{{icp}}</a></div>
        </div>
        <div class="footer__br d-none d-lg-block">
          <div class="footer_brList">
            <img src="//www.mashibing.com/img/footer.png" alt />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      bdList: {
        title: '联系我们',
        data: [
          // 'QQ：1669964656',
          '邮箱：767169735@qq.com',
          '工作时间：8:00~24:00',
        ],
      },
      hdList: [
        {
          title: '关于我们',
          link: '/aboute.html',
          data: ['企业简介', '人才招聘', '师资力量', '企业服务'],
        },
        {
          title: '服务保障',
          link:
            this.$customerServiceLink,
          data: ['分期付款', '服务协议', '退款规则', '发票申请'],
        },
        {
          title: '服务中心',
          link:
            this.$customerServiceLink,
          data: ['报名流程', '咨询客服', '反馈建议', '我要投诉'],
        },
      ],
      icp: "京ICP备17012835号-1",
      company: "© 2020 马士兵（北京）教育科技有限公司",
      address: "北京市海淀区北三环中路44号4号楼1层114"
    }
  },
  mounted(){
    fetch('/data.json').then(res=> res.json()).then(data => {
      if(data){
        let icp = ''
        let company = ''
        let address = ''
        for(const key of Object.keys(data)) {
          if(location.hostname.includes(key)) {
            const item = data[key];
            icp = item.icp;
            company = item.company;
            address = item.address;
            break;
          }
        }
        this.icp = icp;
        this.company = company;
        this.address = address;
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  &__top {
    margin-top: 20px;
    font-size: 11px;
    margin-bottom: 13px;
    text-align: center;
  }
  &__t {
    // height: 250px;
    display: flex;
    padding-top: 43px;
    padding-bottom: 24px;
    border-bottom: 1px solid #595959;
    box-sizing: border-box;
    justify-content: space-between;
  }
  &__hd {
    width: 380px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
  }
  &__hdList {
    flex-shrink: 0;
    & + .footer__hdList {
      margin-left: 35px;
    }
  }
  &__hdList a,
  &__hdList span {
    display: block;
    color: #cccccc;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
  }
  &__hdList span:nth-child(1) {
    font-size: 22px;
    margin-bottom: 30px;
    color: white;
  }
  &__split {
    height: 133px;
    width: 1px;
    background: #595959;
    margin-top: 20px;
  }
  &__hdCard {
    height: 140px;
    width: 110px;
    background: white;
    color: black;
    padding: 5px;
    img {
      margin-top: 5px;
      width: 100%;
    }
    + .footer__hdCard {
      margin-left: 35px;
    }
  }
  &__bd {
    display: flex;

    justify-content: space-between;
  }
  &__bdCardList {
    display: flex;
  }
  &__b {
    display: flex;
    justify-content: space-between;
    height: 95px;
    padding-top: 20px;
    box-sizing: border-box;
  }
  &__bl {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 14px;
    color: #757575;
  }
  &__address {
    margin-bottom: 10px;
  }
}

@media (max-width: 992px) {
  .footer {
    position: relative;
    padding-top: 40px;
    .footer__top {
      display: block !important;
      position: absolute;
      top: 10px;
    }
    .footer__bd,
    .footer__b {
      width: 100%;
    }
    .footer__bd {
      justify-content: center;
    }
    .footer__bl {
      width: 100%;
      text-align: center;
    }
    .footer__t {
      border: none;
    }
  }
  .footer__address {
    font-size: 12px;
  }
}
</style>
