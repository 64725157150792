<template>
  <div class="card container">
    <div class="tab-title" v-if="tabIndex === 0">
      <u-title title="小白入门" />
      <span @click="tabIndex = 1">技术进阶</span>
    </div>
    <div class="tab-title" v-if="tabIndex === 1">
      <span @click="tabIndex = 0">小白入门</span>
      <u-title title="技术进阶" />
    </div>
    <div class="card__box">
      <div class="card__title"></div>
      <div class="card__switch">
        <div
          class="card__slist"
          v-for="(item, index) in switchTabData[tabIndex]"
          :key="index"
          :style="item.styleObj"
        >
          <div class="card__stitle">{{ item.title }}</div>
          <img src="../../../../../assets/arrow.png" class="card__sarrow" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const page1 = require('../../../../../assets/page1.png')
const page2 = require('../../../../../assets/page2.png')
const page3 = require('../../../../../assets/page3.png')
const page4 = require('../../../../../assets/page4.png')
const page5 = require('../../../../../assets/page5.png')
export default {
  data() {
    return {
      tabIndex: 0,
      switchTabData: {
        0: [
          {
            title: 'Java工程师',
            color: '#FFF5D8',
            styleObj: {
              backgroundImage: `url(${page1})`,
            },
          },
          {
            title: 'web前端工程师',
            color: '#DCFFF3',
            styleObj: {
              backgroundImage: `url(${page2})`,
            },
          },
          {
            title: 'golang工程师',
            color: '#FFE2E2',
            styleObj: {
              backgroundImage: `url(${page3})`,
            },
          },
          {
            title: 'web安全工程师',
            color: '#FFE2E2',
            styleObj: {
              backgroundImage: `url(${page4})`,
            },
          },
          {
            title: '测试开发工程师',
            color: '#FFE2E2',
            styleObj: {
              backgroundImage: `url(${page5})`,
            },
          },
        ],
        1: [
          {
            title: 'Web前端全栈工程师',
            color: '#FFF5D8',
            styleObj: {
              backgroundImage: `url(${page1})`,
            },
          },
          {
            title: 'Java互联网架构师',
            color: '#DCFFF3',
            styleObj: {
              backgroundImage: `url(${page2})`,
            },
          },
          {
            title: 'Python全栈工程师',
            color: '#FFE2E2',
            styleObj: {
              backgroundImage: `url(${page3})`,
            },
          },
          {
            title: '大数据架构师',
            color: '#FFE2E2',
            styleObj: {
              backgroundImage: `url(${page4})`,
            },
          },
          {
            title: 'AI人工智能',
            color: '#FFE2E2',
            styleObj: {
              backgroundImage: `url(${page5})`,
            },
          },
        ],
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.tab-title {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    margin: 0 6px;
  }
}
.card {
  &__switch {
    display: flex;
    width: 100%;
    // justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  &__slist {
    width: 200px;
    height: 93px;
    border-radius: 10px;
    text-align: left;
    position: relative;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    margin-right: 27px;
    margin-bottom: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__stitle {
    font-size: 18px;
    margin-top: 27px;
    margin-left: 20px;
  }
  &__sarrow {
    height: 8px;
    width: 34px;
    position: absolute;
    right: 27px;
    bottom: 15px;
  }
}
</style>
