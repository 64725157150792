<template>
  <div class="phone">
    <first-screen />
    <!-- 热门训练营 -->
    <hot />
    <!-- 精品小课 -->
    <excellent-course />
    <!-- 名师大咖 -->
    <teacher />
    <!-- 项目实战 -->
    <project />
    <!-- 为什么选择我们 -->
    <choose-us />
    <!-- 合作企业内推 -->
    <introversion />
    <Footer />
    <live />
    <p-fix></p-fix>
  </div>
</template>

<script>
import chooseUs from '../home/components/chooseUs'
import introversion from '../home/components/introversion'
import excellentCourse from '../home/components/excellentCourse'
import hot from '../home/components/hot'
import teacher from '../home/components/teacher'
import firstScreen from '../home/components/firstScreen'
import project from '../home/components/project'
import Footer from '@/components/Footer'
import PFix from '@/components/Footer/phone-fix'
export default {
  name: 'phone',
  components: {
    Footer,
    chooseUs,
    introversion,
    excellentCourse,
    hot,
    teacher,
    firstScreen,
    project,
    PFix
  },
  data() {
    return {}
  },
  props: {
    cardList: Array,
    reasonList: Array,
    slide: Array
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/functions.scss';
.phone {
  .card {
    display: flex;
    justify-content: center;
    &__box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    &__title {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      position: relative;
      color: black;
      > span {
        z-index: 2;
      }
      > img {
        position: absolute;
        height: 8px;
        bottom: 0;
      }
    }
    &__show {
      position: relative;
      > img {
        position: absolute;
        z-index: -1;
        height: 13px;
        bottom: 0;
        left: 0;
      }
    }
    &__content {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow: hidden;
      .swiper-container {
        height: 100%;
        &__list {
          width: 158px;
          height: 124px;
        }
      }
    }
    &__list {
      width: 159px;
    }

    &__cuser {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: white;
      text-align: left;
      height: 18px;
      display: flex;
      align-items: center;
      img {
        width: 12px;
        height: 8px;
        margin: 0 5px 0 5px;
        z-index: 20;
      }
      span {
        z-index: 20;
        font-size: 11px;
      }
    }

    &__pbottom {
      text-align: left;
    }
    &__ptitle {
      color: black;
      font-size: 14px;
      font-weight: bold;
      margin-top: 18px;
    }
    &__pdetail {
      font-size: 12px;
      color: #7a7a7a;
      margin-top: 18px;
    }
    &__chover {
      height: 100%;
      width: 100%;
      position: absolute;
      background: black;
      opacity: 0.3;
    }
    &__detail {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
    }
    &__price {
      display: flex;
      align-items: center;
    }
    &__new {
      font-size: 16px;
      color: #fe6221;
      margin-right: 7px;
      font-size: 11px;
    }
    &__old {
      color: #9a9a9a;
      font-size: 14px;
      text-decoration: line-through;
      margin-right: 4px;
      font-size: 9px;
    }
    &__tip {
      font-size: 14px;
      color: #ff4b00;
      font-size: 10px;
      text-align: left;
    }
    &__person {
      color: #7b7b7b;
      display: flex;
      align-items: center;
      font-size: 9px;
    }
    &__more {
      width: 114px;
      height: 28px;
      border: 1px solid rgba(182, 51, 136, 1);
      border-radius: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
      line-height: 28px;
      font-size: 12px;
      box-sizing: border-box;
      color: #b63388;
      text-align: center;
      cursor: pointer;
    }
    &__disabled {
      align-items: center;
      color: #6e6e6e;
      font-size: 22px;
    }
    &__switch {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    &__slist {
      width: 200px;
      height: 93px;
      border-radius: 10px;
      text-align: left;
      position: relative;
      cursor: pointer;
    }
    &__stitle {
      font-size: 20px;
      margin-top: 27px;
      margin-left: 27px;
    }
    &__sarrow {
      height: 8px;
      width: 34px;
      position: absolute;
      right: 8px;
      bottom: 15px;
    }
    &__prouct {
      color: #7b7b7b;
      font-size: 16px;
      text-align: left;
      margin-top: 20px;
    }
    &__uimg {
      height: 14px;
      width: 20px;
      margin-right: 6px;
    }
    &__blist {
      height: 180px;
      /deep/ .swiper-slide {
        overflow: unset;
        height: 100%;
        width: 100%;
        font-size: 50px;
        text-align: center;
        margin: 0 10px;
      }
      /deep/ .swiper-slide img {
        height: 100%;
        width: 200px;
      }
      /deep/ .swiper-container {
        height: 100%;
      }
      /deep/ .swiper-container-free-mode > .swiper-wrapper {
        -webkit-transition-timing-function: linear; /*之前是ease-out*/
        -moz-transition-timing-function: linear;
        -ms-transition-timing-function: linear;
        -o-transition-timing-function: linear;
        transition-timing-function: linear;
        margin: 0 auto;
      }
    }
    &__teacher {
      height: 100%;
      position: relative;
    }
    &__tdetail {
      position: absolute;
      top: 0;
      z-index: 20;
      background: beige;
      width: 480px;
      height: 230px;
      background: rgba(194, 28, 131, 1);
      opacity: 0.8;
      color: white;
      padding: 20px 25px;
      box-sizing: border-box;
    }
    &__tldetail {
      text-align: left;
      left: 200px;
    }
    &__trdetail {
      text-align: right;
      right: 200px;
    }
    &__tname {
      font-size: 24px;
    }
    &__tposition {
      font-size: 16px;
      margin-top: 8px;
    }
    &__tdescribe {
      font-size: 14px;
      margin-top: 16px;
    }
    &__thover {
      position: absolute;
      top: 0;
      left: 0;
      height: 180px;
      width: 200px;
      background: #c21c83;
      opacity: 0.4;
    }
    &__choose {
      width: 100%;
    }
    &__video {
      video {
        height: 100%;
        width: 100%;
        object-fit: fill;
        border-radius: 5px;
      }
    }
    &__reason {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 30px;
    }
    &__reasonList {
      width: 48%;
      text-align: left;
    }
    &__reasonTitle {
      font-size: 14px;
      color: #d75685;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    &__reasonTitle span:nth-child(1) {
      width: 3px;
      height: 3px;
      background: rgba(182, 51, 136, 1);
      opacity: 0.3;
      border-radius: 50%;
      display: block;
    }
    &__reasonTitle span:nth-child(2) {
      width: 6px;
      height: 6px;
      background: rgba(182, 51, 136, 1);
      opacity: 0.3;
      border-radius: 50%;
      margin-left: 2px;
      margin-right: 5px;
    }
    &__reasonSpan {
      color: #8b8b8b;
      font-size: 11px;
      margin-top: 10px;
    }
    &__honor {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
    &__honorList {
      width: 150px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        transition: all 0.6s;
        cursor: pointer;
      }
      img:hover {
        transform: scale(1.1);
      }
    }
    &__unite {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      flex-wrap: wrap;
    }
    &__uniteList {
      width: 109px;
      height: 42px;
      padding: 10px 20px;
      box-sizing: border-box;
      background: white;
      margin-bottom: 14px;
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.11);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.6s;
        cursor: pointer;
      }
      img:hover {
        transform: scale(1.1);
      }
    }
  }
}

.boss {
  /deep/ .swiper-container {
    width: 135px;
    height: 122px;
    overflow: visible !important;
  }
  .swiper-wrapper .swiper-slide {
    width: 110px;
    border-radius: 20px;
  }
  .swiper-pagination {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #8a91a4;
    bottom: dim(-80) !important;
  }
}
.footer {
  background: black;
  color: white;
  overflow: hidden;

  &__bdCardList {
    display: flex;
    justify-content: center;
  }
  &__hdCard {
    margin-right: 15px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    background: white;
    color: black;
    img {
      height: 66px;
      width: 66px;
      margin: 4px;
    }
  }
  &__bl {
    margin-top: 17px;
    font-size: 10px;
    color: #747474;
    margin-bottom: 13px;
  }
}
</style>
