<template>
  <div>
    <div class="header">
      <img
        class="drop"
        @click="showMenu"
        src="../../../../../assets/left.png"
        alt=""
      />
      <div class="header-logo">
        <img class="msblogo" src="//www.mashibing.com/img/msblogo.png" alt />
      </div>
      <div class="tel">
        <img class="tel__icon" src="../../../../../assets/tel.png" alt />
        <div class="tel__number">4000015096</div>
      </div>
    </div>
    <div class="banner">
      <swiper :options="swiperOption">
        <swiper-slide
          class="swiper-slide"
          v-for="(item, index) in bannerList"
          :key="index"
        >
          <a :href="item.link" target="_blank">
            <img :src="item.src" alt />
          </a>
        </swiper-slide>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="navbar">
      <a
        :href="item.link"
        class="navbar__list"
        :target="item.target"
        v-for="(item, index) in navbarList"
        :key="index"
      >
        <img class="navbar__icon" :src="item.src" alt />
        <div class="navbar__title">
          {{ item.title }}
        </div>
        <div class="navbar__text">{{ item.text }}</div>
      </a>
    </div>
    <transition>
      <div v-if="menu">
        <div class="menu-mask" @click="menu = false"></div>
        <div class="menu-list">
          <a
            :href="item.mobileLink || item.link"
            target="_blank"
            v-for="(item, index) in tabList"
            :key="index"
            @click="navClick(item)"
            >{{ item.title }}</a
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ['swiperOption', 'slide', 'tabList', 'courseList'],
  data() {
    return {
      bannerList: [
        {
          src: '//www.mashibing.com/img/wapbanner2.jpg',
          link: 'https://ke.qq.com/course/package/20773',
        },
        // {
        //   src: '//www.mashibing.com/img/wapbanner3.jpg',
        //   link: 'https://ke.qq.com/course/package/27380',
        // },
        {
          src: '//www.mashibing.com/img/wapbanner1.jpg',
          link: 'https://ke.qq.com/course/package/20773',
        },
        // {
        //   src: '//www.mashibing.com/img/wapbanner6.jpg',
        //   link: 'https://ke.qq.com/course/398321',
        // },
        {
          src: '//www.mashibing.com/img/wapbanner7.jpg',
          link: 'https://ke.qq.com/course/2770807',
        },
      ],
      // 手机navbar
      navbarList: [
        {
          src: '//www.mashibing.com/img/navbar1.png',
          title: 'JavaEE',
          link: 'https://ke.qq.com/course/2438176',
          text: '编程语言界中流砥柱',
        },
        {
          src: '//www.mashibing.com/img/navbar2.png',
          title: 'Python全栈',
          link: 'https://ke.qq.com/course/2379892',
          text: '人工智能主流语言',
        },
        {
          src: '//www.mashibing.com/img/navbar3.png',
          title: 'Web安全渗透',
          text: '网络安全一专多能',
          link: 'https://ke.qq.com/course/2837583',
        },
        {
          src: '//www.mashibing.com/img/navbar4.png',
          title: 'Web前端全栈',
          link: 'https://ke.qq.com/course/2737328',
          text: '多重技能加身',
        },
        {
          src: '//www.mashibing.com/img/navbar5.png',
          title: 'AI人工智能',
          link: 'https://ke.qq.com/course/423416',
          text: '引领未来科技生活',
        },
        {
          src: '//www.mashibing.com/img/navbar6.png',
          title: '大数据',
          link: 'https://ke.qq.com/course/398321',
          text: '时代前言尖端技术',
        },
      ],
      menu: false,
    };
  },
  methods: {
    showMenu() {
      this.menu = true;
    },
    navClick() {
      this.menu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin: 0 20px;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .header-logo {
    height: 40px;
    width: 95px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .drop {
    height: 14px;
    width: 17px;
    position: absolute;
    left: 0;
    bottom: 6px;
  }
  .tel {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    img {
      height: 14px;
      width: 14px;
      margin-right: 5px;
    }
    &__number {
      font-size: 12px;
      color: #494949;
    }
  }
}
.banner {
  // height: 180px;
  // height: calc((100vh - 40px) / 3.6);
  border-radius: 5px;
  overflow: hidden;
  margin: 20px;
  .swiper-slide {
    border-radius: 5px;
    overflow: hidden;
  }
  .swiper-container {
    height: 100%;
    img {
      object-fit: fill;
      height: 100%;
      width: 100%;
    }
  }
  /deep/ .swiper-pagination {
    width: 72px;
    height: 14px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
  }
  /deep/ .swiper-pagination-bullet {
    background: #dbdbdb;
  }
  /deep/ .swiper-pagination-bullet-active {
    background: white;
  }
}

.navbar {
  // height: 233px;
  display: flex;
  flex-wrap: wrap;
  background: white;
  border-radius: 5px;
  margin: 20px;
  &__list {
    width: 33.3%;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__icon {
    height: 50px;
    width: 52px;
  }
  &__title {
    font-size: 11px;
    color: #000000;
    margin-top: 5px;
  }
  &__text {
    font-size: 12px;
    color: #646464;
    word-break: keep-all;
    transform: scale(0.9);
    color: #646464;
  }
}
.menu-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 998;
}
.menu-list {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  background: #fff;
  z-index: 999;
  align-content: flex-start;
  padding-top: 30px;

  a {
    width: 100%;
    display: block;
    color: #353535;
    font-weight: 500;
    padding-left: 30px;
    line-height: 50px;
  }
}
</style>
