/*
 * @Author: your name
 * @Date: 2021-03-20 12:08:05
 * @LastEditTime: 2021-03-22 07:20:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /mashibing/src/common/common.js
 */

const clientWidth = window.screen.availWidth

const isPC = clientWidth >= 1200
const isPad = clientWidth >= 768 && clientWidth < 1200
const isPhone = clientWidth < 768
console.log('test screen')
export {
    isPC,
    isPad,
    isPhone
}
// export default obj
