<template>
  <div class="quality bgfff">
    <u-title title="精品小课" />
    <div class="d-flex container flex-wrap quality-list">
      <div
        class="quality-item"
        v-for="(item, index) in qualityList"
        :key="index"
      >
        <a :href="item.href" target="_blank">
          <div class="card__picture">
            <img :src="item.src" alt />
          </div>
          <div class="quality-title text-left ellipsis">
            <!-- multi-ellipsis--l2 -->
            {{ item.product }}
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="quality-price">￥{{ item.price }}</div>
            <div class="d-flex align-items-center quality-person">
              <img
                src="../../../../../assets/user.png"
                class="card__uimg"
                alt
              />
              {{ item.person }}人报名
            </div>
          </div>
        </a>
      </div>
    </div>
    <a class="more-btn" target="_blank" :href="$customerServiceLink"
      >查看更多</a
    >
  </div>
</template>

<script>
// 课程

export default {
  data () {
    return {
      // 精品小课
      qualityList: [
        {
          product: 'HTML+CSS零基础速成【马士兵教育】',
          price: 199,
          person: 151,
          href: 'https://ke.qq.com/course/2645515',
          src: '//www.mashibing.com/img/course1.jpg?v',
        },
        {
          product: 'Java入门+实战训练营',
          price: 299,
          person: 481,
          href: 'https://ke.qq.com/course/2556408',
          src: '//www.mashibing.com/img/course2.jpg?v',
        },
        {
          product: '数据结构与算法【马士兵教育】',
          price: 98,
          person: 7000,
          src: '//www.mashibing.com/img/course3.jpg?v',
          href: 'https://ke.qq.com/course/429723',
        },
        {
          product: '抽丝剥茧设计模式【马士兵教育】',
          price: 50,
          person: 8829,
          src: '//www.mashibing.com/img/course4.jpg?v',
          href: 'https://ke.qq.com/course/413747',
        },
        {
          product: '自定义注解',
          price: 1,
          person: 403,
          src: '//www.mashibing.com/img/course5.jpg?v',
          href: 'https://ke.qq.com/course/458641',
        },
        {
          product: 'Spring源码设计｜从入门到精通【马士兵教育】',
          price: 298,
          person: 2812,
          src: '//www.mashibing.com/img/course6.jpg?v',
          href: 'https://ke.qq.com/course/2769085',
        },
        {
          product: 'Hadoop零基础从入门到精通',
          price: 298,
          person: 88,
          src: '//www.mashibing.com/img/course7.jpg?v',
          href: 'https://ke.qq.com/course/432890',
        },
        {
          product: '架构师高级技能kubernetes入门到精通',
          price: 198,
          person: 6788,
          src: '//www.mashibing.com/img/course8.jpg?v',
          href: 'https://ke.qq.com/course/458634',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.quality {
  .quality-item {
    width: calc(50% - 13px);
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 14px;
    &:nth-child(2n) {
      margin-left: 13px;
    }
    &:nth-child(2n - 1) {
      margin-right: 13px;
    }
    .quality-price {
      font-size: 16px;
      font-weight: 400;
      color: rgba(240, 39, 46, 1);
      line-height: 26px;
    }
  }
  .quality-title {
    // height: 42px;
    margin: 12px 0;
  }
  .quality-person {
    font-size: 14px;
    font-weight: 400;
    color: rgba(123, 123, 123, 1);
    font-weight: 500;
    font-size: 14px;
    img {
      width: 20px;
      height: 14px;
    }
  }
  .card__picture {
    overflow: hidden;
    border-radius: 2px;
    position: relative;
  }
  .card__picture > img {
    height: calc((25% - 20px) / 1.7);
    width: 100%;
    transition: all 0.6s;
    cursor: pointer;
  }
  .card__picture > img:hover {
    transform: scale(1.1);
  }
  .more-btn {
    margin: 30px 0;
  }
}
@media (min-width: 768px) {
  .quality {
    .quality-item {
      width: calc(25% - 20px);
      margin-right: 26px !important;
      margin-left: 0 !important;
      &:nth-child(4n) {
        margin-right: 0 !important;
      }
    }
  }
}
</style>
