<template>
  <div class="home">
    <PC
      v-if="isPC"
      :honorList="honorList"
      :websiteList="websiteList"
      :swiperBosslOption="swiperBosslOption"
      :swiperBossrOption="swiperBossrOption"
    />
    <PHONE
      v-else
      :honorList="honorList"
      :websiteList="websiteList"
      :swiperBosslOption="swiperBosslOption"
      :swiperBossrOption="swiperBossrOption"
    />
  </div>
</template>

<script>

import Vue from 'vue'
import vueSwiper from 'vue-awesome-swiper'
import PC from '../views/pc/home'
import PHONE from '../views/phone/home'
import { isPC, isPhone, isPad } from '@/common/common.js'
import 'swiper/css/swiper.css'
Vue.use(vueSwiper)

// 底部
export default {
  metaInfo: {
    title: '马士兵老师的官方网站'
  },
  name: 'Home',
  components: { PC, PHONE },
  data () {
    return {
      isPhone: isPhone,
      isPC: isPC || isPad,
      // 我们的荣誉
      honorList: [
        '//www.mashibing.com/img/glory1.png',
        '//www.mashibing.com/img/glory2.png',
        '//www.mashibing.com/img/glory3.png',
      ],
      // 网站
      websiteList: [
        {
          title: '阿里云',
          link: 'https://www.aliyun.com/',
        },
        {
          title: 'bilibili',
          link: 'https://www.bilibili.com/',
        },
        {
          title: '今日头条',
          link: 'https://www.toutiao.com/',
        },
        {
          title: '阿里巴巴',
          link: 'https://www.alibaba.com/',
        },
        {
          title: '腾讯课堂', // 腾讯课堂
          link: 'https://ke.qq.com/',
        },
      ],
      // 底部
      // bossSwiper
      swiperBosslOption: {
        // freeMode: true,
        autoplay: {
          delay: 0, //1秒切换一次
        },
        loop: true,
        speed: 3000,
        loopAdditionalSlides: 1,
        slidesPerView: Math.ceil(document.body.clientWidth / 200),
      },

      swiperBossrOption: {
        // freeMode: true,
        autoplay: {
          delay: 0, //1秒切换一次
          reverseDirection: true,
        },
        loop: true,
        speed: 3000,
        loopAdditionalSlides: 1,
        slidesPerView: Math.ceil(document.body.clientWidth / 200),
      },
    }
  },
  mounted () { },
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
@media (max-width: 768px) {
  .home {
    background: #f4f4f4;
  }
}
.home {
  height: 100%;
  width: 100%;
}
</style>
