<template>
  <div><component :is="type" :proList="proList" /></div>
</template>

<script>
import { isPC } from '@/common/common.js'
import pc from './pc'
import phone from './phone'
export default {
  components: {
    phone,
    pc,
  },
  data() {
    return {
      proList: [
        {
          name: '咚宝商城',
          introIntro:
            '该项目，导师立项目标是带着学员，再造一个千万级并发的在线购物商城。跟随当年淘宝的技术发展，导师带领学员，一步一步，从单体应用，到微服务。从业务系统集中，到拆分各个中台，订单，商品，支付等等。项目中核心功能包括：用户系统，订单系统，支付系统，商品系统，促销系统，消息系统等 电商核心解决方案。',
          skillIntro:
            'Git，Maven，Spring Boot，Spring Cloud，Redis，MySql ，ElasticSearch，RocketMQ，Kafka，ETCD，Zookeeper',
        },
        {
          name: '飞滴出行',
          introIntro:
            '该项目是一款标准网约车应用。符合我国交通部对网约车监管的技术要求。通过了交通部对网约车线上和线下能力认定。曾在杭州上线运行。  项目中核心功能包括：账户系统，订单系统，支付系统，地图引擎，派单引擎，消息系统等 网约车核心解决方案。  项目中完全采用微服务架构设计，应用了成熟的接口安全设计方案，采用分布式锁保证了分布式环境中的数据同步，用分布式事务解决了分布式环境中的数据一致性等。',
          skillIntro:
            'Git，Maven，Spring Boot，Spring Cloud，Redis，MySql ，RabbitMQ，ActiveMQ',
        },
        {
          name: '合家云服务平台',
          introIntro:
            '该项目，导师立项目标是从0到1带着很多没有做过项目的同学搭建一个前后端分离的服务平台。该平台主要致力于实现物业管理的数据管理和业务过程管理，通过物业管理系统的实施提高企业管理效率，利用先进的信息技术为业主提供完美的物业管理和服务。 项目中包含多个核心模块：房产管理、销售管理、业主管理、收费管理、服务管理、保安管理、社区消防、保洁绿化、统计分析，行政管理。',
          skillIntro:
            'Git，Maven，Spring，springboot，mybatis，mybatis-plus,mysql,vue',
        },
        {
          name: '《Hero Story》',
          introIntro: `该游戏是一款欧美卡通风格的 MMORPG 游戏，目前正在研发中， 2020 年准备发布在 Google Play 上，进军欧美市场。 该游戏技术框架基于国内知名网页游戏 《回到三国志》， 《回到三国志》 上线腾讯应用中心，并创下每个月 2000 万流水的 骄人战绩。 《Hero Story（英雄传说）》在此基础之上做了大量升级和优化，`,
          skillIntro:
            '多线程与高并发 JavaSE Maven、Git IO NIO 反射 Netty Dubbo、RocketMQ  ELK（ElasticSearch + Logstash + Kibana） Jenkins + Ansible',
        },
      ],
    }
  },
  computed: {
    type() {
      return isPC ? 'pc' : 'phone'
    },
  },
}
</script>

<style></style>

<style lang="scss" scoped></style>
